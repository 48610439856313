<template>
  <QRPaymentLayout>
    <div class="qr-payment">
      <div v-if="isLoading" class="loader-container dark">
        <div>
          <QuickSubscribeBackground />
          <LoaderSpinner />
          <div
            class="qr-payment-text with-side-padding"
            v-html="getTranslation('qr_payment_wait')"
          />
        </div>
      </div>

      <template v-else>
        <QSPayment
          v-if="$store.QS.QRPayment.isError"
          with-icon
          :is-error="true"
          :title="
            $store.QS.serverError
              ? getTranslation(`qs_${$store.QS.serverError}`)
              : getTranslation('link_is_invalid')
          "
          :description="getTranslation('qr_payment_fail')"
        />

        <QSPayment
          v-else-if="$store.QS.QRPayment.isPaid"
          with-icon
          :title="title"
          :description="getTranslation('qr_payment_success_1')"
        >
          <div class="mb-8">
            <span v-html="getTranslation('qr_payment_expires_at')" />&#32;
            <span v-html="$store.QS.QRPayment.expiresAt" />.
          </div>
          <div
            v-if="getTranslation('qr_payment_success_2')[0] !== '$'"
            v-html="getTranslation('qr_payment_success_2')"
          />
        </QSPayment>

        <QuickSubscribe v-else-if="isQS" />
      </template>
    </div>
  </QRPaymentLayout>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';
import {
  HTTP_CODES,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_STATUSES_REASONS,
  QS_SOURCE_TYPES,
} from 'src/constants';
import * as api from 'src/api';
import { expireDate } from 'src/utils/time/date';
import { convertToMilliseconds } from 'src/utils/time/convert-time';
import {
  isPaymentSuccessful,
  checkPaymentStatusTrials,
  getPaymentStatus,
} from 'src/utils/quick-subscribe';
import QuickSubscribe from 'src/components/quick-subscribe/QuickSubscribe.vue';
import QRPaymentLayout from 'src/layouts/QRPaymentLayout.vue';
import QuickSubscribeBackground from 'src/components/quick-subscribe/QuickSubscribeBackground.vue';
import QSPayment from 'src/components/quick-subscribe/QSPayment.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { getVodSubscriptionOptions } from 'src/store/quick-subscribe/actions';
import logger from 'src/utils/logger';
import { getChannelTitle } from 'src/utils/channel';
import { TChannelEnhanced } from 'src/api/channels/types';

interface TTokenData {
  account_id?: string;
  content_type?: string;
  provider_id?: string;
  transaction_id: string;
  tv_channel_id: string;
  vod_source_id: string;
  vod_title_id?: string;
}

const log = logger('qr-payment');

@Component({
  components: {
    QRPaymentLayout,
    QuickSubscribe,
    QSPayment,
    LoaderSpinner,
    QuickSubscribeBackground,
  },
})
export default class QRPaymentPage extends SequoiaComponent {
  isLoading = true;
  tokenData: TTokenData = {
    transaction_id: '',
    tv_channel_id: '',
    vod_source_id: '',
  };
  expiresAt = 0;
  token = '';
  subscriptionId = '';
  isQS = false;

  get title() {
    const title = this.$store.QS.QRPayment.title || '';
    return this.getTranslation('qr_subscribed').replace('%title%', title);
  }

  async mounted() {
    this.expiresAt = parseInt(this.$route.query.expires_at.toString());
    this.token = this.$route.query.token as string;
    this.subscriptionId = this.$route.query.subscription_id as string;
    if (Number(this.expiresAt)) {
      setTimeout(() => {
        this.isLoading = false;
        this.$store.QS.QRPayment.isError = true;
      }, convertToMilliseconds(this.expiresAt, 'second') - selectors.appInfo.serverTimeMsSelector(this.$store));
    }

    try {
      await this.loadTokenData();
    } catch (err) {
      log.error(err);
      this.isLoading = false;
      this.$store.QS.QRPayment.isError = true;
      return;
    }

    if (!this.$store.QS.QRPayment.isError) {
      await actions.profile.getAccount(this.$store, {
        params: { token: this.token },
      });
      await actions.tvChannels.loadChannelsAndGenres(this.$store);

      if (this.subscriptionId) {
        try {
          await this.loadOfferInfo();
        } catch (err) {
          log.error(err);
          this.$store.QS.QRPayment.isError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        try {
          const { state } = await api.bow.qsStateForTransaction(this.tokenData.transaction_id, {
            params: { token: this.token },
          });
          const isError = state === 'success';

          this.$store.QS.QRPayment.isError = isError;
          if (isError) {
            this.isLoading = false;
          }
        } catch (err) {}
        this.isLoading = false;
      }

      if (!this.$store.QS.QRPayment.isPaid) {
        if (this.tokenData?.content_type === 'tv') {
          await this.loadTvQS();
        } else if (this.tokenData?.content_type === 'vod') {
          await this.loadVodQS();
        }
        this.isQS = true;
      }
    }
  }

  getChannelTitle(channel: TChannelEnhanced) {
    return getChannelTitle(channel);
  }

  async loadTokenData() {
    this.tokenData = await api.bow.getTokenData({
      bowProxyDisabled: true,
      params: { token: this.token },
    });
  }

  async loadOfferInfo() {
    const { status, cancellation_details: cancellationDetails } = await getPaymentStatus(
      this.subscriptionId,
      {
        params: { token: this.token },
      }
    );
    let isPaid = isPaymentSuccessful(status);

    if (
      status === SUBSCRIPTION_STATUSES.CANCELED &&
      cancellationDetails.reason === SUBSCRIPTION_STATUSES_REASONS.CANCELED_BY_MERCHANT
    ) {
      // in case of trials there will be payment of about 1 rouble to check whether credit card is valid and has money
      // and straight after that there will be refund of this 1 rouble so the payment will be canceled
      // so in case if status is canceled - we should check whether subscription is active or not
      isPaid = await checkPaymentStatusTrials(this.subscriptionId, this.token);
    }

    if (isPaid) {
      try {
        await actions.payment.loadPaymentSubscriptions(this.$store, {
          params: { token: this.token },
        });
      } catch (err) {
        log.error('onLoadPaymentSubscriptions', err);
      }

      const SUBSCRIPTION = this.$store.payment.subscriptions.find((s) => {
        return s.id === this.subscriptionId;
      });

      const OFFER_ID = SUBSCRIPTION?.offer?.id;

      if (OFFER_ID) {
        const offer = await api.offers.getOffer(OFFER_ID);
        this.$store.QS.QRPayment.expiresAt = expireDate(SUBSCRIPTION?.expires_at);
        this.$store.QS.QRPayment.title = offer?.preview?.title;
      }

      this.$store.QS.QRPayment.isPaid = true;
    }
  }

  async loadTvQS() {
    const currentChannel = this.$store.tvChannels.list[this.tokenData.tv_channel_id];

    this.$store.QS.sourceType = QS_SOURCE_TYPES.CHANNEL;
    // TODO fix typess
    this.$store.QS.channel = currentChannel;
    this.$store.QS.currentSourceId = currentChannel?.id;

    try {
      await actions.QS.getTvV3SubscriptionOptions(this.$store, this.tokenData.tv_channel_id, {
        params: { token: this.token },
      });
    } catch (err) {
      if ([undefined, HTTP_CODES.NOT_FOUND].includes(err.status)) {
        await actions.QS.getTvSubscriptionOptions(this.$store, this.tokenData.tv_channel_id, {
          params: { token: this.token },
        });
      }
    }

    if (selectors.QS.currentTemplateComponentSelector(this.$store)) {
      if (currentChannel) {
        currentChannel.name = this.getChannelTitle(currentChannel);
      }

      try {
        await actions.payment.loadPaymentMethod(this.$store, false, {
          params: { token: this.token },
        });
      } catch (err) {
        log.error('onLoadPaymentMethod', err);
      }
    } else {
      this.$store.QS.QRPayment.isError = true;
    }
  }

  async loadVodQS() {
    const TOKEN = this.token.toString();
    const SOURCE_ID = this.tokenData.vod_source_id;
    this.$store.QS.sourceType = QS_SOURCE_TYPES.VOD;

    // TODO fix types
    this.$store.QS.vod = {
      name: SOURCE_ID,
      episodeId: '',
      sourceId: '',
    };

    this.$store.QS.currentSourceId = SOURCE_ID;

    try {
      await actions.QS.getVodV3SubscriptionOptions(this.$store, {
        params: { sourceId: SOURCE_ID, token: TOKEN },
      });
    } catch (err) {
      if ([undefined, HTTP_CODES.NOT_FOUND].includes(err.status)) {
        await getVodSubscriptionOptions(this.$store, {
          params: { sourceId: SOURCE_ID, token: TOKEN },
        });
      }
    }
    if (selectors.QS.currentTemplateComponentSelector(this.$store)) {
      try {
        await actions.payment.loadPaymentMethod(this.$store, false, {
          params: { token: this.token },
        });
      } catch (err) {
        log.error('onLoadPaymentMethod', err);
      }
    } else {
      this.$store.QS.QRPayment.isError = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.qr-payment {
  min-height: 640px;
  @include mobile {
    min-height: 100%;
  }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qr-payment-text {
    position: relative;
    margin-bottom: 150px;
  }

  .account-note {
    margin-bottom: 32px;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: var(--c-dark-font-primary);
    letter-spacing: 0.2px;

    .account-name {
      font-weight: bold;
    }
  }
}
</style>
