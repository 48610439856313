<template>
  <div class="layout-qr-payment overlay-default-before overlay-accent-after">
    <ModalSequoia
      class="modal-quick-subscribe"
      theme-forced="dark"
      type="fixed"
      size="large"
      min-height="600"
      :with-logo="true"
      :with-button-close="false"
      :with-button-back="withButtonBack"
      :with-fixed-header="withButtonBack"
      @back="handleQuickSubscribeModalStepBack"
    >
      <slot />
    </ModalSequoia>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import ModalSequoia from 'src/components/ui/ModalSequoia.vue';
import { selectors } from 'src/store/selectors';
import { actions } from 'src/store/actions';

@Component({
  components: {
    ModalSequoia,
  },
})
export default class QRPaymentLayout extends Vue {
  get currentStepComponentName() {
    return selectors.QS.currentStepComponentNameSelector(this.$store);
  }

  get withButtonBack() {
    return (
      this.currentStepComponentName === 'SubscriptionDetails' ||
      (this.currentStepComponentName === 'AboutSubscription' &&
        this.$store.QS.paymentRetry &&
        !this.$store.QS.QRPayment.isPaid &&
        !this.$store.QS.QRPayment.isError)
    );
  }

  mounted() {
    document.documentElement.style.backgroundColor = '#101010';
  }

  destroyed() {
    document.documentElement.style.backgroundColor = 'unset';
  }

  handleQuickSubscribeModalStepBack() {
    actions.QS.handleModalStepBack(this.$store);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/overlays';

.layout-qr-payment {
  position: relative;
  flex-grow: 1;
  height: 100vh;

  &.overlay-default-before {
    @include overlay-color-before(var(--c-dark-100));
  }

  .modal-quick-subscribe::v-deep {
    .dialog {
      background-color: var(--c-dark-150);
    }

    @include mobile {
      .logo {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
