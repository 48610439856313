<template>
  <main class="qr-success-page container-1440">
    <StatusMessage
      :status="true"
      :title="getTranslation('qr_success')"
      :text="getTranslation('qr_success_notes')"
      @clickButton="goToMainPage"
    />
  </main>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import StatusMessage from 'src/components/StatusMessage.vue';

@Component({
  components: { StatusMessage },
})
export default class QRSuccessPage extends SequoiaComponent {
  mounted() {
    this.gaEvent({ category: 'qr_login', action: 'Успешный логин по QR-коду' });
  }
}
</script>
