<template>
  <main class="qr-login-page container-1440">
    <LoaderSpinner />
  </main>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import { actions } from 'src/store/actions';

@Component({
  components: { LoaderSpinner },
})
export default class QRLoginPage extends SequoiaComponent {
  code = '';
  timer?: NodeJS.Timeout;
  schema = '';
  timeout = 2000;

  async mounted() {
    this.code = this.$route.query.code as string;
    this.schema = this.$store.siteConfig?.appStores?.scheme;

    window.onblur = () => {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.goToAuth();
    };

    if (this.isAnonymous) {
      this.openApp();
    } else {
      try {
        await actions.authAndReg.QRLogin(this.$store, this.code);
        await this.$router.replace('/qr-success');
      } catch (err) {}
    }
  }

  openApp() {
    this.timer = setTimeout(this.goToAuth, this.timeout);
    if (this.schema) {
      window.location.href = `${this.schema}qr-login/?code=${this.code}`;
    }
  }

  goToAuth() {
    this.$router.replace(`/login?qr=${this.code}`);
  }
}
</script>
